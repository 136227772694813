import styled from "@emotion/styled/macro";
import Card from "./Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useIsMobile } from "hooks/useIsMobile";
import { ButtonGroupProps } from "react-multi-carousel/lib/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromotions } from "store/slices/promotion/asyncThunks";
import { ThunkAppDispatch, promotionSelector } from "store";
import { SkeletonContainer } from "components/SkeletonContainer";
import { authActions } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { keyframes } from "@emotion/react";

const MainContainerRightGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 28px;
  width: 15%;
  pointer-events: none;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.53) 39.44%,
    #fff 100%
  );
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const MainContainerLeftGradient = styled.div`
  animation: ${(props) => (props.hidden ? fadeOut : fadeIn)} 0.3s ease-in-out;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10%;
  pointer-events: none;

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.53) 39.44%,
    #fff 100%
  );
`;

const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 0.5rem;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  background-color: #fff;
  width: 28px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  width: 28px;
  height: 48px;
  border-radius: 100px;
  background: #f5f5f5;
  cursor: pointer;
`;

const MainSection = styled.div`
  position: relative;
  .carousel-container {
    height: fit-content;
    touch-action: pan-y;
  }
  li div {
    overflow: hidden;
  }
  .carousel-item {
    box-sizing: border-box;
  }

  @media (max-width: 588px) {
    margin-bottom: 20px;

    .carousel-item {
      padding-right: 12px;
    }
  }
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 3.7,
    slidesToSlide: 30,
  },
  tabletL: {
    breakpoint: { max: 1440, min: 980 },
    items: 2.5,
  },
  tabletX: {
    breakpoint: { max: 980, min: 860 },
    items: 3.8,
  },
  tabletM: {
    breakpoint: { max: 860, min: 820 },
    items: 3.6,
  },
  tablet: {
    breakpoint: { max: 820, min: 750 },
    items: 3.3,
  },
  tabletXS: {
    breakpoint: { max: 750, min: 700 },
    items: 3.1,
  },
  mobileL: {
    breakpoint: { max: 700, min: 660 },
    items: 2.9,
  },
  mobileX: {
    breakpoint: { max: 660, min: 620 },
    items: 2.7,
  },
  mobileM: {
    breakpoint: { max: 620, min: 588 },
    items: 2.5,
  },
  mobile: {
    breakpoint: { max: 588, min: 525 },
    items: 3.2,
  },
  mobileS: {
    breakpoint: { max: 525, min: 500 },
    items: 3,
  },
  mobileXS: {
    breakpoint: { max: 500, min: 470 },
    items: 2.7,
  },

  mobileXXS: {
    breakpoint: { max: 470, min: 440 },
    items: 2.5,
  },
  mobileXXXS: {
    breakpoint: { max: 440, min: 380 },
    items: 2.3,
  },
  mobileXXXXS: {
    breakpoint: { max: 380, min: 350 },
    items: 2.1,
  },
  mobileXXXXXS: {
    breakpoint: { max: 350, min: 320 },
    items: 1.9,
  },
};

interface CarouselButtonGroupProps extends ButtonGroupProps {
  hidden: boolean;
  currentSlide: number;
  totalItems: number;
  isMatch: boolean;
}

const ButtonGroup = ({
  next,
  previous,
  hidden,
  currentSlide,
  totalItems,
  isMatch,
}: CarouselButtonGroupProps) => {
  if (isMatch) return null;
  if (hidden) return <ButtonsBlock />;

  const isLastItem = currentSlide >= totalItems - 4;

  return (
    <ButtonsBlock>
      {isLastItem ? (
        <Button onClick={() => previous && previous()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            style={{ transform: "rotate(180deg)" }}
          >
            <path
              d="M5.90137 4L10.4014 8.5L5.90137 13"
              stroke="#739B67"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      ) : (
        <Button onClick={() => next && next()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M5.90137 4L10.4014 8.5L5.90137 13"
              stroke="#739B67"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      )}
    </ButtonsBlock>
  );
};

const MainSlider = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const isMatch = useMediaQuery("(max-width:588px)");
  const dispatch = useDispatch<ThunkAppDispatch>();
  const { promotions, loading } = useSelector(promotionSelector);
  const [dragging, setDragging] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const skeleton = [1, 2, 3, 4];

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        await dispatch(getAllPromotions()).unwrap();
      } catch (err: any) {
        if (err.response && err.response.data.code === "WRONG_DEVICE") {
          dispatch(
            authActions.setAnotherDeviceError({
              title: err.response.data.title,
              description: err.response.data.subtitle,
            })
          );
          navigate("/login-error");
        }
      }
    };

    fetchPromotions();
  }, [dispatch, navigate]);

  if (!loading && !promotions.length) return null;

  return (
    <div>
      <MainSection>
        <Carousel
          arrows={false}
          responsive={responsive}
          swipeable={true}
          containerClass="carousel-container"
          itemClass="carousel-item"
          draggable={true}
          removeArrowOnDeviceType={[
            "desktop",
            "tabletL",
            "tabletX",
            "tabletM",
            "tablet",
            "tabletXS",
            "mobileL",
            "mobileX",
            "mobileM",
            "mobile",
            "mobileS",
            "mobileXS",
            "mobileXXS",
            "mobileXXXS",
            "mobileXXXXS",
            "mobileXXXXXS",
          ]}
          partialVisible={true}
          renderButtonGroupOutside={true}
          beforeChange={() => setDragging(true)}
          afterChange={(previousSlide, { currentSlide }) => {
            setCurrentSlide(currentSlide);
            setDragging(false);
          }}
          customButtonGroup={
            <ButtonGroup
              hidden={loading || (!isMatch && promotions.length < 5)}
              currentSlide={currentSlide}
              totalItems={promotions.length}
              isMatch={isMatch}
            />
          }
        >
          {!loading
            ? promotions.map((card) => (
                <Card key={card.id} card={card} processCardClick={!dragging} />
              ))
            : skeleton.map((item) => (
                <SkeletonContainer
                  height={isMobile ? 134 : 168}
                  width={isMobile ? 182 : 287}
                  isLoading={true}
                  key={item}
                />
              ))}
        </Carousel>

        {!isMatch && !loading && <MainContainerRightGradient />}
        {!isMatch && !loading && (
          <MainContainerLeftGradient hidden={currentSlide <= 0} />
        )}
      </MainSection>
    </div>
  );
};

export default MainSlider;
