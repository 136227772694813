import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { LimitType } from "api/account/api";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { formatAmount } from "utils/formatCurrency";

type Props = {
  id: number;
  subtitle: string;
  title: string;
  selected: boolean;
  onClick: (id: number) => void;
  limitCurrentAmount: string;
  limitSumAmount: string;
  readOnly: boolean;
  svg: any;
};

export const rusSubtitle = (subtitle: string) => {
  switch (subtitle) {
    case LimitType.Daily:
      return "Суточный лимит";
    case LimitType.Weekly:
      return "Еженедельный лимит на";
    case LimitType.Monthly:
      return "Месячный лимит на";
    case LimitType.Quarterly:
      return "Квартальный лимит на";
    case LimitType.Yearly:
      return "Годовой лимит на";
  }
};

export const LimitItem = ({
  id,
  title,
  onClick,
  svg = null,
  limitCurrentAmount,
  limitSumAmount,
  readOnly,
}: Props) => {
  const theme = useTheme();
  const {
    cards: {
      mainCard,
      account: { currency },
    },
  } = useSelector((state: any) => state);

  const handleClick = () => {
    if (mainCard.status === "ACTIVE") {
      onClick(id);
    }
  };
  const [activeIndexItem, setActiveIndexItem] = useState<number | null>(null);
  const { blue } = theme.palette;
  const { isMobile, size } = useIsMobile();

  return (
    <Box
      p={12}
      display="flex"
      alignItems="center"
      bgcolor={
        activeIndexItem === id
          ? "var(--main-color-white)"
          : "var(--main-color-bg-widgets)"
      }
      borderRadius="16px"
      sx={{
        cursor: "pointer",
      }}
      {...(!readOnly && {
        onClick: handleClick,
        onMouseEnter: () => setActiveIndexItem(id),
        onMouseLeave: () => setActiveIndexItem(null),
      })}
    >
      <IconButton
        sx={{
          mr: 12,
          backgroundColor:
            activeIndexItem === id
              ? "var(--main-color-bg-widgets)"
              : "var(--main-color-white)",
          borderRadius: isMobile ? 20 : 8,
          width: isMobile ? 36 : 40,
          height: isMobile ? 36 : 40,
        }}
      >
        {svg && svg({ readOnly })}
      </IconButton>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="text_5"
          color={
            readOnly
              ? theme.primary.main.gray["400"]
              : theme.primary.main.gray["800"]
          }
        >
          {title}
        </Typography>
        <Typography
          variant="text_3"
          color={theme.primary.main.gray["400"]}
          mt={4}
        >
          {formatAmount(limitCurrentAmount, currency.sign, true)} /{" "}
          {formatAmount(limitSumAmount, currency.sign, true)}
        </Typography>
      </Box>
      {!readOnly && (
        <Box sx={{ ml: "auto" }}>
          <IconChevronRight color="var(--main-color-text-title)" />
        </Box>
      )}
    </Box>
  );
};
