import { Box } from "@mui/material";
import { MainGridItem } from "components/Grid";
import { useEffect } from "react";
import { ChatWidget } from "widgets/ChatWidget/ChatWidget";
import { ClickableHeader } from "./ClickableHeader";
import { systemActions } from "store/slices/system";
import { useDispatch, useSelector } from "react-redux";
import { systemSelector } from "store";

function ChatScreen() {
  const { previousTab } = useSelector(systemSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainGridItem columns={{ xs: [1, -1] }}>
      <Box sx={{ overflow: "hidden" }}>
        <ClickableHeader
          onBack={() =>
            dispatch(systemActions.setActiveTab({ tab: previousTab }))
          }
        />
        <Box
          sx={{ height: "91svh", display: "flex", justifyContent: "center" }}
        >
          <ChatWidget />
        </Box>
      </Box>
    </MainGridItem>
  );
}

export default ChatScreen;
