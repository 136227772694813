import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  PrepareAction,
  current,
} from "@reduxjs/toolkit";
import { cardApi } from "api";
import {
  AccountDtoV2,
  AccountUpdRequest,
  CardBlockRequest,
  CardCvvRequest,
  CardCvvTokenRequest,
  CardDto,
  CardPanDto,
  CardRequest,
  CardUpdateRequest,
  ResetPinRequest,
} from "api/account";
import { checkAvailableVirtualCard } from "./virtualCardSlice";
import { SHOW_VIRTUAL_CARD } from "utils/constants";

export enum CARD_ACTION {
  INITIAL = "INITIAL",
  LOST = "LOST",
  TOPUP = "TOPUP",
  PAY = "PAY",
  TRANSFER = "TRANSFER",
  TEMPORARY = "TEMPORARY",
  LIMIT = "LIMIT",
  REFERRAL = "REFERRAL",
  // TEMPORARY = "TEMPORARY", TODO убрать после прохождения теста FYN-4587
  CHANGE_PIN = "CHANGE_PIN",
  CHANGE_NAME = "CHANGE_NAME",
  CHANGE_PIN_INFO = "CHANGE_PIN_INFO",
  CHANGE_NAME_INFO = "CHANGE_NAME_INFO",
  UNSUCCESS = "UNSUCCESS",
  UNSUCCESS_STATUS_CHANGE = "UNSUCCESS_STATUS_CHANGE",
  UNFROZEN = "UNFROZEN",
  REFILL = "REFILL",
  REFILL_ERROR = "REFILL_ERROR",
  SHOW_HISTORY = "SHOW_HISTORY",
}

export interface ErrorMessage {
  code?: string;
  title?: string;
  subtitle?: string;
  comment?: string;
}

export type CardState = {
  isLoading: boolean;
  isBackCardLoading: boolean;
  isConfirmed: boolean;
  isAccountUpdating: boolean;
  error: string;
  errorMessage: ErrorMessage;
  account: AccountDtoV2;
  mainCard: CardDto;
  anotherCards: Array<CardDto>;
  allCards: Array<CardDto>;
  fromTransferCard: CardDto;
  fullCardPan: string;
  requisitesCard: CardPanDto;
  verifyCvvToken: "";
  cvvModalVisible: boolean;
  cvv: string;
  isBlockLoading: boolean;
  isCardInfoOpen: boolean;
  isCardActionsOpen: boolean;
  chosenCardIndex?: number;
  screen: CARD_ACTION;
  isAccountInfoOpen: boolean;
  chosenAccountNumber?: number;
};

const initialState: CardState = {
  isLoading: false,
  isBackCardLoading: false,
  isAccountUpdating: false,
  isConfirmed: false,
  isCardActionsOpen: false,
  isCardInfoOpen: false,
  chosenCardIndex: undefined,
  isAccountInfoOpen: false,
  chosenAccountNumber: undefined,
  // TODO заменить in app ошибки error на объект errorMessage
  error: "",
  errorMessage: {
    code: "",
    title: "",
    subtitle: "",
    comment: "",
  },
  account: {},
  mainCard: {
    cardPan: "",
    cardId: "",
    status: undefined,
    amount: 0,
    cardTypeDescription: "",
  },
  anotherCards: [],
  allCards: [],
  fromTransferCard: {
    amount: 0,
  },
  fullCardPan: "",
  requisitesCard: {
    cardId: "",
    cardPan: "",
    lifetime: "",
  },
  verifyCvvToken: "",
  cvvModalVisible: false,
  cvv: "",
  isBlockLoading: false,
  screen: CARD_ACTION.INITIAL,
};

export const setStatement = createAsyncThunk(
  "setStatement",
  async (
    payload: {
      data: { accId: string; forceRefresh: boolean };
      withCheck?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data, withCheck } = payload;
      const response = await cardApi.getAccountInfoV2("", data);
      if (withCheck && SHOW_VIRTUAL_CARD) dispatch(checkAvailableVirtualCard());

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCardInfo = createAsyncThunk(
  "updateCardInfo",
  async (payload: CardUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.updateCardInfo("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCardInfo = createAsyncThunk(
  "getCardInfo",
  async (payload: CardRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.getCardInfo("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "updateAccount",
  async (payload: AccountUpdRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.updateAccount("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const blockedCard = createAsyncThunk(
  "blockedCard",
  async (payload: CardBlockRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.blockCard("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const unblockedCard = createAsyncThunk(
  "unblockedCard",
  async (payload: CardRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.unblockCard("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPin = createAsyncThunk(
  "resetPin",
  async (payload: ResetPinRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.resetPinViaSms("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStatusPin = createAsyncThunk(
  "getStatusPin",
  async (payload: string, { rejectWithValue }) => {
    try {
      await cardApi.getStatus("string", payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCardPanInfo = createAsyncThunk(
  "getCardPanInfo",
  async (payload: CardRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.getCardPanInfo("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTokenCardCvvInfo = createAsyncThunk(
  "getTokenCardCvvInfo",
  async (payload: CardCvvTokenRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.getTokenCardCvvInfo("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCardCvvInfo = createAsyncThunk(
  "getCardCvvInfo",
  async (payload: CardCvvRequest, { rejectWithValue }) => {
    try {
      const response = await cardApi.getCardCvvInfo("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setMainCard = createAction<PrepareAction<CardDto>>(
  "setMainCard",
  (card) => {
    localStorage.setItem("cardId", card.cardId);
    return {
      payload: card,
    };
  }
);

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    resetStore: (state) => {
      state.account = {};
      state = initialState;
    },
    setAnotherCards: (state, { payload }) => {
      state.anotherCards = payload;
    },
    unLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setNewStatusCard: (state, { payload }) => {
      state.mainCard.status = payload;
    },
    updateMainCard(state, payload: any) {
      state.mainCard = payload;
    },
    updateSomeValueMainCard(state, payload: any) {
      state.mainCard.cardPan = payload?.cardPan;
      state.mainCard.cardTypeDescription = payload?.cardTypeDescription;
      state.mainCard.status = payload?.status;
    },
    closeCvvModal(state) {
      state.cvvModalVisible = false;
    },
    clearCvv(state) {
      state.cvv = "";
    },
    setFromTransferCard(state, { payload }: PayloadAction<CardDto>) {
      state.fromTransferCard = payload;
    },
    setIsCardInfoOpen(state, { payload }) {
      state.isCardInfoOpen = !state.isCardInfoOpen;
      state.chosenCardIndex = payload;
    },
    setChosenCardIndex(state, { payload }) {
      state.chosenCardIndex = payload;
    },
    cardActionsToggle(state) {
      state.isCardActionsOpen = !state.isCardActionsOpen;
    },
    setScreen(state, { payload }: PayloadAction<CARD_ACTION>) {
      state.screen = payload;
      state.isCardActionsOpen = true;
    },
    setIsAccountInfoOpen(state, { payload }) {
      state.isAccountInfoOpen = !state.isAccountInfoOpen;
      state.chosenAccountNumber = payload;
    },
    setCardError: (state, action: PayloadAction<ErrorMessage>) => {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      setMainCard,
      (state, { payload }: PayloadAction<CardDto>) => {
        state.mainCard = payload;
      }
    );
    builder.addCase(
      setStatement.fulfilled.type,
      (state, { payload }: PayloadAction<Array<AccountDtoV2>>) => {
        const cardId = localStorage.getItem("cardId");
        state.isLoading = false;
        state.account = payload[0];
        if (payload[0]?.cards) {
          state.allCards = payload[0]?.cards;
        }
        const mainCard =
          payload[0].cards?.find((el) => el.cardId === cardId) ||
          payload[0].cards?.[0];
        const anotherCards = payload[0].cards?.filter(
          (el) => el.cardId !== mainCard?.cardId
        );
        cardsSlice.caseReducers.updateMainCard(state, mainCard);
        if (anotherCards) {
          state.anotherCards = anotherCards;
        }
      }
    );
    builder.addCase(setStatement.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(resetPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPin.rejected.type, (state, { payload }: any) => {
      const error = payload?.response?.data;
      state.isLoading = false;
      state.error = error || undefined;
    });

    builder.addCase(
      getCardInfo.fulfilled.type,
      (state, { payload }: PayloadAction<CardDto[]>) => {
        const card = payload[0];

        state.allCards = state.allCards.map((el) =>
          el.cardId === card.cardId ? card : el
        );

        const anotherCardIndex = state.anotherCards.findIndex(
          (el) => el.cardId === card.cardId
        );
        if (anotherCardIndex !== -1) {
          state.anotherCards[anotherCardIndex] = card;
        }

        if (state.mainCard.cardId === card.cardId) {
          state.mainCard = card;
        }
        state.isBlockLoading = false;
      }
    );
    builder.addCase(getCardInfo.pending, (state) => {
      state.isBlockLoading = true;
    });
    builder.addCase(getCardInfo.rejected.type, (state, { payload }: any) => {
      state.isBlockLoading = false;
      state.error = payload?.response?.data || undefined;
    });

    builder.addCase(
      unblockedCard.fulfilled.type,
      (state, { payload }: PayloadAction<CardDto>) => {
        cardsSlice.caseReducers.updateSomeValueMainCard(state, payload);
        state.allCards = state.allCards.map((el) =>
          el.cardId === payload.cardId ? { ...el, status: "ACTIVE" } : el
        );
        state.isBlockLoading = false;
      }
    );
    builder.addCase(unblockedCard.pending, (state) => {
      state.isBlockLoading = true;
    });
    builder.addCase(unblockedCard.rejected.type, (state, { payload }: any) => {
      const error = payload?.response?.data;
      state.isBlockLoading = false;
      state.error = error || undefined;
    });

    builder.addCase(
      blockedCard.fulfilled.type,
      (state, { payload }: PayloadAction<CardDto>) => {
        cardsSlice.caseReducers.updateSomeValueMainCard(state, payload);
        state.allCards = state.allCards.map((el) =>
          el.cardId === payload.cardId ? { ...el, status: payload.status } : el
        );
        state.isBlockLoading = false;
      }
    );
    builder.addCase(blockedCard.pending, (state) => {
      state.isBlockLoading = true;
    });
    builder.addCase(blockedCard.rejected.type, (state, { payload }: any) => {
      const error = payload?.response?.data;
      state.isBlockLoading = false;
      state.error = error || undefined;
    });

    builder.addCase(getCardCvvInfo.rejected.type, (state, { payload }: any) => {
      const error = payload?.response?.data;
      state.error = error || undefined;
    });
    //ИЗМЕНЕНИЕ НАЗВАНИЯ СЧЕТА
    builder.addCase(updateAccount.fulfilled, (state, { payload }: any) => {
      state.isAccountUpdating = false;
      state.account.description = payload.description;
    });
    builder.addCase(updateAccount.pending, (state) => {
      state.isAccountUpdating = true;
    });
    builder.addCase(updateAccount.rejected.type, (state: any) => {
      state.isAccountUpdating = false;
    });
    builder.addCase(updateCardInfo.fulfilled, (state, { payload }) => {
      state.isAccountUpdating = false;

      const cardsArray = [...current(state).allCards];
      const cards = cardsArray.map((card) => {
        if (card.cardId === payload.cardId) {
          return { ...card, description: payload.description };
        }
        return card;
      });

      //@ts-ignore
      state.allCards = cards;
    });
    builder.addCase(updateCardInfo.pending, (state) => {
      state.isAccountUpdating = true;
    });
    builder.addCase(updateCardInfo.rejected.type, (state: any) => {
      state.isAccountUpdating = false;
    });
  },
});

export const cardsActions = cardsSlice.actions;
export const {
  updateMainCard,
  setFromTransferCard,
  setIsCardInfoOpen,
  setIsAccountInfoOpen,
  clearCvv,
  setAnotherCards,
  unLoading,
  setNewStatusCard,
  closeCvvModal,
  cardActionsToggle,
  setChosenCardIndex,
  setScreen,
  setCardError,
} = cardsSlice.actions;
export default cardsSlice.reducer;
