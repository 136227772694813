import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Hidden, Typography } from "@mui/material";
import { Limits } from "./Limits";
import { Transfers } from "./Transfers";
import { History } from "./History";
import { Promotion } from "./Promotion";
import { Main } from "./Main";
import { systemActions } from "store/slices/system";
import { systemSelector, chatSelector } from "store";
import IconChat from "components/Icons/IconChat";
import { useIsMobile } from "hooks/useIsMobile";
import { MobileTabVariants } from "store/slices/system/slice";
import { EventSourcePolyfill } from "event-source-polyfill";
import { chatActions } from "../../../store/slices/chat";
import env from "../../../env";
import { authApi } from "api";
import { useIsPWA } from "hooks/useIsPWA";
import { authActions } from "store/slices/auth";
import TransfersHub from "./TransfersHub";
import { ChatWidget } from "widgets/ChatWidget/ChatWidget";
import { IconMove } from "atoms/IconMove/IconMove";
import { IconSquare } from "atoms/IconSquare/IconSquare";
import { useOutsideClick } from "hooks/useOutsideClick";

const content: Record<string, React.ReactNode> = {
  transfers: <Transfers />,
  transfersHub: <TransfersHub />,
  limits: <Limits />,
  history: <History />,
  promotion: <Promotion />,
  main: <Main />,
};

const Cards = () => {
  const dispatch = useDispatch();
  const tabsRef = useRef<null | HTMLElement>(null);
  const { activeTab, needToScrollToTabs } = useSelector(systemSelector);
  const accessToken = sessionStorage.getItem("accessToken");
  const { newMessageCounter } = useSelector(chatSelector);
  const [chatListening, setChatListening] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();

  const handleChatClick = () => {
    if (isMobile) {
      dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
    } else {
      dispatch(systemActions.setChatOpen(true));
      setIsModalOpen(false);
    }
  };

  const refresh = useCallback(async () => {
    const response = await authApi.refreshToken({
      deviceId:
        (isPWA
          ? localStorage.getItem("PWADeviceId")
          : localStorage.getItem("deviceId")) || "",
    });
    const { accessToken } = response.data;
    if (accessToken) {
      sessionStorage.setItem("accessToken", accessToken);
      dispatch(authActions.setNewAccessToken(accessToken));
    }
  }, [dispatch, isPWA]);

  useEffect(() => {
    if (needToScrollToTabs) {
      const tabComponent = tabsRef;
      tabComponent?.current?.scrollIntoView({
        block: "start",
        inline: "end",
        behavior: "smooth",
      });
      dispatch(systemActions.setNeedScrollState(false));
    }
  }, [dispatch, needToScrollToTabs, activeTab]);

  useEffect(() => {
    if (!chatListening && accessToken) {
      const url = `${env.REACT_APP_BASE_PATH}notification/v1/chat/subscription/new-messages`;
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        heartbeatTimeout: 120000,
      };
      const es = new EventSourcePolyfill(url, options);

      es.addEventListener("open", () => {
        setChatListening(true);
      });

      es.addEventListener("message", (event) => {
        localStorage.setItem("lastKnownNewMessageCount", event.data);
        dispatch(chatActions.setNewMessageCounter(Number(event.data)));
      });

      es.addEventListener("error", (err) => {
        const lastRefreshDate = sessionStorage.getItem("lastRefreshDate");

        const isNeedRefresh = () => {
          if (!lastRefreshDate) return true;

          const dateNow = new Date();
          return (
            Math.floor(
              (new Date(dateNow).getTime() -
                new Date(lastRefreshDate).getTime()) /
                60000
            ) < 15
          );
        };

        // @ts-ignore
        if (err.status === 401 && isNeedRefresh()) {
          if (!lastRefreshDate)
            sessionStorage.setItem("lastRefreshDate", new Date().toString());
          refresh();
        }
        es.close();
        setChatListening(false);
      });
    }
  }, [dispatch, refresh, chatListening, accessToken]);

  const chatElRef = useOutsideClick(() => setIsModalOpen(false), {
    useCapture: true,
  });

  return (
    <Box>
      {content[activeTab]}

      <Hidden lgDown>
        <Box
          ref={chatElRef}
          sx={{
            position: "fixed",
            bottom: "140px",
            right: "120px",
            borderRadius: "22px",
            boxShadow: "0px 11px 9.3px 0px rgba(132, 144, 129, 0.24)",
            background: "#739B67",

            width: "56px",
            height: "56px",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setIsModalOpen((prev) => !prev)}
          >
            {isModalOpen ? (
              <IconSquare />
            ) : (
              <IconChat count={newMessageCounter} />
            )}
          </Box>

          {isModalOpen && (
            <Box
              sx={{
                position: "absolute",
                bottom: "calc(100% + 22px)",
                right: 0,
                borderRadius: "14px",
                background: "#FFF",
                boxShadow: "0px 11px 9.3px 0px rgba(132, 144, 129, 0.24)",
                height: "533px",
                maxHeight: "533px",
                width: "376px",

                boxSizing: "border-box",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  borderRadius: "12px 12px 4px 4px",
                  background: "#739B67",
                  padding: 16,

                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                  zIndex: 99,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  Чат
                </Typography>
                <Box
                  onClick={handleChatClick}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconMove />
                </Box>
              </Box>
              <Box
                sx={{
                  boxSizing: "border-box",
                  height: "calc(533px-60px)",
                  maxHeight: "calc(533px-60px)",

                  overflow: "hidden",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,

                  paddingTop: "60px",
                }}
              >
                <ChatWidget isWindow />
              </Box>
            </Box>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};

export default Cards;
