import { Modal } from "molecules/Modal/Modal";
import { AddLimit } from "./AddLimit";
import { Confirmation } from "./Confiramtion";
import { Screens } from "./models";
import { Success } from "./Success";
import { UnSuccess } from "./UnSuccess";
import { CardLimitConfirmResponse } from "../../../../api/account";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { clearError } from "store/slices/limitsSlice";
import { authActions } from "store/slices/auth";

type Props = {
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  screen: Screens;
  onChangeScreens: (screen: Screens) => void;
  limitItem: CardLimitConfirmResponse;
};

export const LimitModal = ({
  isOpen,
  isMobile,
  onClose,
  screen,
  onChangeScreens,
  limitItem,
}: Props) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => state.limits);
  const isOtpBlockedError = error.code === "OTP_BLOCKED";

  const screens: Record<Screens, React.ReactNode> = {
    initial: (
      <AddLimit
        onClick={onChangeScreens}
        nextScreen="confirmation"
        limitItem={limitItem}
        onClose={onClose}
      />
    ),
    confirmation: (
      <Confirmation onClick={onChangeScreens} nextScreen="success" />
    ),
    success: <Success onClick={onClose} limitItem={limitItem} />,
    unsuccess: (
      <UnSuccess
        onClick={() => {
          onClose();
          dispatch(clearError());
        }}
        payload={isOtpBlockedError ? error : undefined}
        buttonTitle={isOtpBlockedError ? "Понятно" : undefined}
      />
    ),
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(authActions.setIsBurgerActive(true));
    } else {
      dispatch(authActions.setIsBurgerActive(false));
    }
  }, [isOpen, dispatch]);

  return (
    <Modal
      title={isMobile ? null : "Изменение лимита"}
      isOpen={isOpen}
      onClose={onClose}
      buttonVisible
      isProfile
      callback={onClose}
    >
      {screens[screen]}
    </Modal>
  );
};
