import { useSelector } from "react-redux";
import { Typography, Box, SvgIcon } from "@mui/material";
import { styled } from "@mui/system";

import { chatSelector, systemSelector } from "store";

const MobileTabItemContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  rowGap: "2px",
});

export const MobileTabItem = ({ tabInfo, ...props }) => {
  const { activeTab } = useSelector(systemSelector);

  const { title, getIcon } = tabInfo;

  const activeColor =
    tabInfo.id === activeTab
      ? "var(--brand-color-primary)"
      : "var(--main-color-text-secondary-unactive)";
  const { newMessageCounter } = useSelector(chatSelector);

  return (
    <MobileTabItemContainer {...props}>
      <SvgIcon>{getIcon(activeColor, newMessageCounter > 0)}</SvgIcon>
      <Typography variant="text_15" color={activeColor}>
        {title}
      </Typography>
    </MobileTabItemContainer>
  );
};
