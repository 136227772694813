import { useDispatch, useSelector } from "react-redux";
import { chatSelector, ThunkAppDispatch } from "store";
import { useEffect, useCallback } from "react";
import { chatActions, getChatMessages } from "store/slices/chat";

const INITIAL_VALUES = {
  page: -1,
  size: 20,
};

export const useGetChatMessages = () => {
  const dispatch = useDispatch<ThunkAppDispatch>();
  const { latestPage } = useSelector(chatSelector);

  const fetchMessages = useCallback(
    async (
      page: number = INITIAL_VALUES.page,
      size: number = INITIAL_VALUES.size
    ) => {
      const result = await dispatch(getChatMessages({ page, size })).unwrap();
      const { messages, size: newSize, page: newPage } = result || {};

      if (messages) dispatch(chatActions.setMessages(messages));
      if (newSize !== undefined)
        dispatch(chatActions.setInitSumMessages(newSize));

      return { page: newPage, size: newSize, messages };
    },
    [dispatch]
  );

  const loadInitialMessages = useCallback(async () => {
    const { page = INITIAL_VALUES.page, size = INITIAL_VALUES.size } =
      await fetchMessages();

    if (size && size <= 20) {
      dispatch(chatActions.setLatestPage(page));
      dispatch(chatActions.setPaginationPage(page - 1));

      if (size < 15 && page > 0) {
        const prevPageResult = await fetchMessages(page - 1);
        dispatch(
          chatActions.setPaginationMessages(prevPageResult.messages || {})
        );
        if (prevPageResult.size !== undefined) {
          dispatch(chatActions.setInitSumMessages(prevPageResult.size));
        }
      }
    }
  }, [dispatch, fetchMessages]);

  const loadLatestMessages = useCallback(async () => {
    if (latestPage !== undefined && latestPage !== null) {
      await fetchMessages(latestPage);
    } else {
      await loadInitialMessages();
    }
  }, [latestPage, fetchMessages, loadInitialMessages]);

  useEffect(() => {
    loadLatestMessages();
  }, [loadLatestMessages]);

  useEffect(() => {
    const interval = setInterval(async () => {
      dispatch(chatActions.setChatMessageState("scrolling"));
      await fetchMessages(latestPage ?? INITIAL_VALUES.page);
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch, latestPage, fetchMessages]);
};

// УБРАТЬ ПОСЛЕ УСПЕШНОГО ТЕСТА ЗАДАЧИ FYN-5811
// СТАРАЯ ВЕРСИЯ ДО РЕФАКТОРИНГА

// import { useDispatch, useSelector } from "react-redux";
// import { chatSelector, store } from "store";
// import { useEffect } from "react";
// import { chatActions, getChatMessages } from "store/slices/chat";

// const initialValues = {
//   page: -1,
//   size: 20,
// };

// export const useGetChatMessages = () => {
//   const dispatch = useDispatch<typeof store.dispatch>();
//   const { latestPage } = useSelector(chatSelector);

//   useEffect(() => {
//     if (latestPage === undefined || latestPage === null) {
//       dispatch(getChatMessages(initialValues))
//         .unwrap()
//         .then((result) => {
//           const { page = -1, size = 20, messages = {} } = result;
//           if (size > 0 && size <= 20) {
//             dispatch(chatActions.setLatestPage(page));
//             dispatch(chatActions.setPaginationPage(page - 1));
//             if (size < 15 && page > 0) {
//               dispatch(chatActions.setMessages(messages));
//               dispatch(getChatMessages({ page: page - 1, size: 20 }))
//                 .unwrap()
//                 .then((res) => {
//                   dispatch(
//                     chatActions.setPaginationMessages(res.messages || {})
//                   );

//                   if (res.size) {
//                     dispatch(chatActions.setInitSumMessages(res.size));
//                   }
//                 });
//             } else if (size >= 15) {
//               dispatch(chatActions.setMessages(messages));
//               dispatch(chatActions.setLatestPage(page));
//               dispatch(chatActions.setPaginationPage(page - 1));
//             }
//           }
//           dispatch(chatActions.setInitSumMessages(size));
//         });
//     } else {
//       dispatch(getChatMessages({ page: latestPage, size: 20 }))
//         .unwrap()
//         .then(({ messages, size }) => {
//           if (messages) dispatch(chatActions.setMessages(messages));

//           if (size) {
//             dispatch(chatActions.setInitSumMessages(size));
//           }
//         });
//     }
//   }, [dispatch, latestPage]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       dispatch(chatActions.setChatMessageState("scrolling"));
//       dispatch(getChatMessages({ page: latestPage || -1, size: 20 }))
//         .unwrap()
//         .then(({ messages, size }) => {
//           if (messages) {
//             dispatch(chatActions.setTimeoutMessages({ messages, size }));
//           }
//         });
//     }, 10000);
//     return () => clearInterval(interval);
//   }, [dispatch, latestPage]);
// };
