import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { TransfersSteps } from "./TransfersSteps";
import { InputNumber, Textarea } from "components";
import { CardInput } from "molecules/CardInput/CardInput";
import { useIsMobile } from "hooks/useIsMobile";
import {
  resetTransferErrorMessage,
  createTransferToCard,
  TRANSFER_ERROR_CODE,
  resetStateTransfer,
  resetConfirmedTransfer,
  setIsOpenModal,
  setTransferClientBankIcon,
} from "store/slices/transferSlice";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { setFromTransferCard, setStatement } from "store/slices/cardsSlice";
import { RootState } from "store";
import CardSelect from "./CardSelect/CardSelect";
import {
  MAX_AMOUNT,
  MAX_TRANSFER_PAN_MIN_LENGTH,
  MAX_TRANSFER_PAN_MAX_LENGTH,
  MAX_TRANSFER_REASON_LENGTH,
} from "constants/limits";
import { ITransferState, TabVariants } from "store/slices/system/slice";
import { CardStatus, TransferInfoResponseTransferParam } from "api/account";
import { systemActions } from "store/slices/system";
import { ClickableHeader } from "./ClickableHeader";
import { Button } from "components/Button";
import { WarningAlert } from "components/WarningAlert/WarningAlert";

export interface PayloadModal {
  cardId: string | undefined;
  toCardPan: string;
  sum: number;
  reason: string;
}

const Transfers = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const {
    cards: {
      mainCard,
      allCards,
      account: { currency },
    },
    transfer: {
      isTransferChecked,
      isTransferConfirmed,
      createdTransfer,
      errorMessage,
      isOpenModal,
      isLoading,
    },
    system: { activeTabState },
  } = useSelector((state: RootState) => state);

  const activeCard = allCards.find((item) => item.status === CardStatus.Active);
  const countActiveCards = useMemo(
    () =>
      allCards.reduce<number>((prev, value) => {
        return value.status === CardStatus.Active ? prev + 1 : prev;
      }, 0),
    [allCards]
  );
  const hasActiveCard = countActiveCards > 0;

  const [payloadModal, setPayloadModal] = useState({} as PayloadModal);
  const [errorAmount, setErrorAmount] = useState("");

  const [transferValues, setTransferValues] =
    useState<ITransferState>(activeTabState);

  const { pan, amount, reason } = transferValues;

  const ERROR_MESSAGE_AMOUNT = "Ошибка: Превышен лимит";

  const toggleModal = (
    repeatValue: string = "",
    {
      toCardPan: pan,
      sum: amount,
      reason,
    }: TransferInfoResponseTransferParam = {}
  ) => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
    dispatch(setIsOpenModal(!isOpenModal));
    if (
      isOpenModal &&
      isTransferChecked &&
      isTransferConfirmed &&
      !createdTransfer
    ) {
      const payload = repeatValue
        ? { pan, amount, reason }
        : { pan: "", amount: undefined, reason: undefined };
      dispatch(
        systemActions.setActiveTab({
          tab: TabVariants.transfersHub,
          state: payload,
        })
      );
      dispatch(
        setStatement({
          data: {
            accId: "%",
            forceRefresh: false,
          },
        })
      );
      dispatch(resetConfirmedTransfer());

      setTransferValues({
        pan: "",
        amount: NaN,
        reason: "",
      });
    }
  };

  const continueHandler = () => {
    dispatch(resetStateTransfer());
    const data = {
      cardId: mainCard?.cardId || "",
      toCardPan: pan || "",
      sum: amount || 0,
      reason: reason || "",
    };
    dispatch(createTransferToCard(data))
      // @ts-ignore
      .unwrap()
      .then((res) => {
        if (res?.limitRestriction) {
          throw new Error("Лимит по карте превышен");
        } else {
          dispatch(setTransferClientBankIcon(res.toClientBankIcon));
          dispatch(setFromTransferCard(mainCard));
          setPayloadModal(data);
          toggleModal();
        }
      })
      .catch((res) => {
        if (res?.response?.status === 400) return;
        setErrorAmount(res.message);
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetTransferErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeTabState.pan) setTransferValues(activeTabState);
  }, [activeTabState]);

  const errorMessageAmount =
    amount && amount >= MAX_AMOUNT ? ERROR_MESSAGE_AMOUNT : "";
  const isPanValid =
    !!pan &&
    pan.length >= MAX_TRANSFER_PAN_MIN_LENGTH &&
    pan.length <= MAX_TRANSFER_PAN_MAX_LENGTH;
  const isAmountValid = !!amount && amount > 0 && !errorMessageAmount;
  const isTransferValid =
    isPanValid && isAmountValid && activeCard?.status === "ACTIVE";
  const isMessageError = errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE;
  const isCardError = errorMessage.code === TRANSFER_ERROR_CODE.CARD;
  const isAmountExceedError =
    errorMessage.code === TRANSFER_ERROR_CODE.AMOUNT_EXCEED_BALANCE;
  const isErrorInFields = isMessageError || isCardError || isAmountExceedError;

  const currencySign = currency?.sign || "";

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  return (
    <>
      {!isOpenModal ? (
        <>
          <Box
            maxWidth={isMobile ? "auto" : "580px"}
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
          >
            <ClickableHeader
              onBack={() => goToHandler(TabVariants.transfersHub)}
            />
            {!activeCard && (
              <Box
                sx={{
                  position: "relative",
                  "@media (max-width: 768px)": {
                    width: "106%",
                    left: "-16px",
                  },
                  "@media (max-width: 560px)": {
                    width: "108%",
                  },
                  "@media (max-width: 420px)": {
                    width: "110%",
                  },
                }}
              >
                <WarningAlert title="У вас нет карт доступных для перевода" />
              </Box>
            )}

            <Box
              display="flex"
              mb="60px"
              mt={20}
              flexDirection="column"
              rowGap={12}
            >
              <InputNumber
                label="Сумма перевода"
                placeholder="Введите cумму"
                disabled={!hasActiveCard}
                suffix={` ${currencySign}`}
                value={amount}
                error={!!errorMessageAmount || isAmountExceedError}
                hint={isAmountExceedError ? errorMessage.title : ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (errorMessage.title && isAmountExceedError) {
                    dispatch(resetTransferErrorMessage());
                  }
                  setErrorAmount("");

                  const value = event.target.value
                    .replace(",", ".")
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  const result =
                    value === "" || isNaN(Number(value))
                      ? undefined
                      : Number(value);

                  setTransferValues({
                    ...transferValues,
                    amount: result,
                  });
                }}
              />
              <div>
                <CardSelect activeCard={activeCard} />
                {(errorMessageAmount || errorAmount) && (
                  <Typography
                    sx={{
                      color: "var(--error-color-icon)",
                      lineHeight: "20px",
                    }}
                  >
                    {errorAmount ? errorAmount : errorMessageAmount}
                  </Typography>
                )}
              </div>
              <CardInput
                transfer
                name="cdNumber"
                label="На карту"
                sublabel="Возможен только на карты МИР"
                labelColor="var(--main-color-text-title)"
                subLabelColor="var(--main-color-text-secondary)"
                value={pan}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (errorMessage.title && isCardError) {
                    dispatch(resetTransferErrorMessage());
                  }
                  setTransferValues({
                    ...transferValues,
                    pan:
                      event.target.value.length <= MAX_TRANSFER_PAN_MAX_LENGTH
                        ? event.target.value
                        : pan,
                  });
                }}
                disabled={!hasActiveCard}
                mb={0}
                errorMessage={isCardError ? errorMessage.title : ""}
                error={isCardError}
                showCustomKeyboard
              />
              <Textarea
                label="Сообщение получателю (1000 символов)"
                value={reason}
                disabled={!hasActiveCard}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  if (errorMessage.title && isMessageError) {
                    dispatch(resetTransferErrorMessage());
                  }
                  setTransferValues({
                    ...transferValues,
                    reason:
                      event.target.value.length <= MAX_TRANSFER_REASON_LENGTH
                        ? event.target.value
                        : event.target.value.substr(
                            0,
                            MAX_TRANSFER_REASON_LENGTH
                          ),
                  });
                }}
                inputColor="var(--main-color-text-title)"
                hint={isMessageError ? errorMessage.title : ""}
                error={isMessageError}
              />
            </Box>
            <Box>
              <Button
                variant="primary"
                onClick={continueHandler}
                disabled={!isTransferValid || isErrorInFields || !hasActiveCard}
                isLoading={isLoading}
                title="Продолжить"
              />
            </Box>
          </Box>
        </>
      ) : (
        <TransfersSteps onClose={toggleModal} payloadModal={payloadModal} />
      )}
    </>
  );
};

export default Transfers;
