import { TabVariants, systemActions } from "store/slices/system";
import { useDispatch } from "react-redux";
import ReplenishImg from "images/replenish.png";
import TransferImg from "images/transfer.png";
import { IconArrow } from "atoms/IconArrowTransfer";
import { AnotherPages } from "store/slices/system/slice";
import LatestTransactions from "./LatestTransactions";
import { useState } from "react";
import { RefillModal } from "components/refillModal/refillModal";
import { refillAction } from "utils/refillAction";
import { SHOW_REFILL } from "utils/constants";
import { setIsOpenModal } from "store/slices/transferSlice";
import {
  CardImg,
  Container,
  IconWrapper,
  Title,
  TransferBtn,
  TransferBtnContainer,
  TransferBtnText,
  TransferWrapper,
} from "./components";

const Main = () => {
  const [refillOpen, setRefillOpen] = useState(false);
  const [successRefill, setSuccessRefill] = useState(true);
  const dispatch = useDispatch();

  const goToHandler = (tab: AnotherPages | TabVariants) => {
    dispatch(setIsOpenModal(false));
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };
  const goToRefill = async () => {
    setRefillOpen(true);
    try {
      const result = await refillAction(dispatch);
      if (result.success) {
        setSuccessRefill(true);
      }
    } catch (error) {
      setSuccessRefill(false);
    }
  };

  return (
    <>
      <Container>
        <Title>Перевести и пополнить</Title>
        <TransferWrapper>
          <TransferBtnContainer>
            <TransferBtn onClick={() => goToHandler(AnotherPages.transfers)}>
              <IconWrapper>
                <IconArrow rotate={45} />
              </IconWrapper>

              <CardImg src={TransferImg} />
              <TransferBtnText>Перевести</TransferBtnText>
            </TransferBtn>

            {SHOW_REFILL && (
              <TransferBtn onClick={goToRefill}>
                <IconWrapper>
                  <IconArrow rotate={180} />
                </IconWrapper>
                <CardImg src={ReplenishImg} />
                <TransferBtnText>Пополнить</TransferBtnText>
              </TransferBtn>
            )}
          </TransferBtnContainer>
        </TransferWrapper>

        <LatestTransactions />
      </Container>

      {/* Refill modal */}
      <RefillModal
        isOpen={refillOpen}
        onClose={() => setRefillOpen(false)}
        isSocces={successRefill}
      />
    </>
  );
};

export default Main;
