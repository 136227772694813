import { Box, Typography } from "@mui/material";
import { CardLimitConfirmResponse } from "api/account";
import { IconBuy } from "atoms/IconBuy/IconBuy";
import { IconDoc } from "atoms/IconDoc/IconDoc";
import { IconWallet } from "atoms/IconWallet/IconWallet";
import { SkeletonContainer } from "components/SkeletonContainer";
import { useIsMobile } from "hooks/useIsMobile";
import { LimitItem } from "pages/home/Cards/Limits/LimitItem";
import { LimitModal } from "pages/home/Cards/Limits/LimitModal";
import { Screens } from "pages/home/Cards/Limits/models";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { hideErrorMessage } from "store/slices/alerts";
import { getLimits } from "store/slices/limitsSlice";

function LimitCard() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState<Screens>("initial");
  const [selected, setSelected] = useState<number | null>(null);

  const [limit, setLimitItem] = useState<CardLimitConfirmResponse>({});

  const handleClose = () => {
    dispatch(hideErrorMessage());
    setIsOpen(!isOpen);
  };

  const handleClick = (id: number) => {
    setLimitItem(
      limits.filter(
        (limit: CardLimitConfirmResponse) => limit?.limitId === id
      )[0]
    );
    setIsOpen(true);
    setSelected(id);
  };
  const {
    limits: { limits, isLoading },
    cards: { chosenCardIndex, allCards },
  } = useSelector((state: RootState) => state);

  const activeCard = allCards[chosenCardIndex || 0];

  useEffect(() => {
    dispatch(getLimits({ cardId: activeCard.cardId as string }));
  }, [activeCard.cardId, dispatch]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setScreen("initial");
        setSelected(null);
      }, 200);
    }
  }, [isOpen]);

  const { isMobile } = useIsMobile();
  const dataIcon: any = useMemo(
    () => [
      ({ readOnly }) => <IconWallet fillOpacity={readOnly ? 0.5 : 1} />,
      ({ readOnly }) => <IconDoc fillOpacity={readOnly ? 0.5 : 1} />,
      ({ readOnly }) => <IconBuy fillOpacity={readOnly ? 0.5 : 1} />,
      ({ readOnly }) => <IconWallet fillOpacity={readOnly ? 0.5 : 1} />,
    ],
    []
  );

  return (
    <>
      <Typography mb={12} fontSize="18px" color="gray.b800" fontWeight={500}>
        Суточный лимит
      </Typography>
      <SkeletonContainer height={400} width="100%" isLoading={isLoading}>
        <Box display="flex" gap={12} flexDirection="column">
          {limits.map(
            (
              {
                limitType,
                limitName,
                limitId,
                limitCurrentAmount,
                limitSumAmount,
                readOnly,
              }: any,
              i
            ) => (
              <LimitItem
                selected={limitId === selected}
                onClick={handleClick}
                key={limitId}
                id={limitId}
                subtitle={limitType}
                title={limitName}
                limitCurrentAmount={limitCurrentAmount}
                limitSumAmount={limitSumAmount}
                readOnly={readOnly}
                svg={dataIcon[i]}
              />
            )
          )}
        </Box>
      </SkeletonContainer>
      <LimitModal
        isOpen={isOpen}
        onClose={handleClose}
        screen={screen}
        onChangeScreens={setScreen}
        limitItem={limit}
        isMobile={isMobile}
      />
    </>
  );
}

export default LimitCard;
